import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';
import paginationComponent from '@/components/Pagination'

export default {
    name: 'Notifications',
    data() {
        return {
            notifications: [],
            totalNotifications: 0
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout,
        paginationComponent
    },
    mounted() {
        this.getNotifications()
    },
    methods: {
        pageChange: function (nr) {
            this.getNotifications(nr);
        },
        getNotifications(pageParamter = 1, limitParamter = this.paginationPerPage()) {
            const self = this;
            const limit = '?limit=' + limitParamter;
            const page = '&page=' + pageParamter;
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/notifications' + limit + page,
                method: 'GET'
            }).then(response => {
                self.notifications = response.data.rows;
                self.totalNotifications = response.data.total_data;
                console.log(self.totalQuestions)
            })
        }
    }
}
